import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as wjcXlsx from '@grapecity/wijmo.xlsx';
import * as wjGrid from '@grapecity/wijmo.grid';
import JSZip from 'jszip';

wjcXlsx.useJSZip(JSZip);

/**
 * @example
 * ```ts
 * exportXLSX(ref.current, 'filename', {
    includeHiddenColumns: true, // defaults to false
    autoCastDate: true, // defaults to true
    formatItem: (excelCell, wijmoColumn) => {
      if (wijmoColumn.binding === 'FullName') {
        excelCell.style = {
        ...excelCell.style,
        borders: {
          right: {
            style: 2,
            color: 'red',
          },
        },
        fill: { color: 'green' },
        font: {
          bold: true,
          italic: true,
          underline: true,
          size: 16,
          },
        };
        excelCell.value = '*****';
      }
    },
    });
  }}
 *
 * ```
 */
export const exportXLSX = async (
  grid: any,
  fileName: string,
  {
    formatItem = undefined,
    includeColumns = undefined,
    includeHiddenColumns = false,
  }: {
    formatItem?: (cell: any, col: any) => void;
    includeColumns?: (column: any) => boolean;
    includeHiddenColumns: boolean;
  } = {
    formatItem: undefined,
    includeColumns: undefined,
    includeHiddenColumns: false,
  }
) => {
  const wb = wjcGridXlsx.FlexGridXlsxConverter.saveAsync(grid, {
    includeColumns: includeColumns,
    formatItem: (args: any) => {
      var p = args.panel,
        col = args.col,
        xlsxCell = args.xlsxCell;
      if (p.cellType === wjGrid.CellType.Cell) {
        if (formatItem) {
          formatItem(xlsxCell, p.columns[col]);
        }
      }
    },
  });
  wb.sheets[0].rows.forEach((r, index) => {
    if (index === 0) {
      r.height = 30;
      r.cells.forEach((c) => {
        c.style.fill.color = '#4C6085';
        c.style.font.bold = true;
        c.style.font.color = 'white';
        c.style.font.size = 16;
      });
      return;
    }
    if (index % 2 === 0) {
      r.cells.forEach((c) => {
        c.style.fill.color = '#F2F2F2';
      });
    } else {
      r.cells.forEach((c) => {
        c.style.fill.color = 'white';
      });
    }
  });
  wb.sheets[0].columns.forEach((c) => {
    if (!c.visible) {
      c.visible = includeHiddenColumns;
    }
    c.width = 210;
  });
  wb.saveAsync(fileName);
};
