import { AxiosError } from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as wj from '@grapecity/wijmo';
// import { KeyAction } from '@grapecity/wijmo.grid'
import { Operator } from '@grapecity/wijmo.grid.filter';
import QubCollectionView from '../../QubCollectionView';
import { findIndex, isFunction } from 'lodash';
import { GridColumn, GridDataStatus, GridRef } from '../../types';
import { QubError } from '../../models/exceptions';
import { raiseNotification } from '../../events';
import { isQubError } from '../../functions/helpers';
import { bringValue } from '../../functions';
import { FlexGridContextMenu } from '../../components/ContextMenu';

const useDataGrid = (props: {
  dataKey?: string; // Making conditional because of setupLater feature for bringValue view
  fetchGridParams?: any;
  fetchGridData?: any;
  commitChanges?: any;
  saveState?: any;
  restoreState?: any;
  setupLater?: boolean;
  collectionViewProps?: any;
  customValidation?: (item: any, prop: any, parsing: any) => string;
  additionalInitialization?: any;
  cellTemplateByColumn?: any;
  bringValueProps?: any;
  flexProps?: object;
  autoSizeColumns?: boolean;
}) => {
  const {
    dataKey,
    fetchGridParams,
    fetchGridData,
    saveState,
    restoreState,
    setupLater,
    collectionViewProps,
    customValidation,
    additionalInitialization,
    commitChanges,
    cellTemplateByColumn,
    bringValueProps,
    flexProps = {},
    autoSizeColumns = true,
  } = props;

  if (
    !setupLater &&
    !isFunction(fetchGridData) &&
    !isFunction(fetchGridParams) &&
    !dataKey
  ) {
    throw "If you don't want to setupLater, please provide fetchGridData and fetchGridParams functions";
  }

  const gridRef = useRef<GridRef>({
    grid: null,
  });
  const [pagination, setPagination] = useState({});
  const [editing, setEditing] = useState<Boolean>(false);
  const [status, setStatus] = useState<GridDataStatus>('loading');
  const [isDirty, setIsDirty] = useState<Boolean>(false);
  const [exportOptions, setExportOptions] = useState<any>([]);
  const [initializedGrid, setInitializedGrid] = useState<Boolean>(false);

  useEffect(() => {
    /**
     * Add custom filter operator
     */
    const filter = wj.culture.FlexGridFilter;

    if (findIndex(filter.stringOperators, { name: 'Custom' }) === -1)
      filter.stringOperators = filter.stringOperators.filter((f: any) => {
        switch (f.op) {
          case Operator.EW:
            return false;
          case Operator.BW:
            f.name = 'Custom';
            return true;
          default:
            return true;
        }
      });
  }, []);

  useEffect(() => {
    /**
     * If there are changes then prompt for confirmation
     * @param e Event
     * @returns
     */
    const confirmExit = (e: any) => {
      if (gridRef.current && gridRef.current && isDirty) {
        e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        e.stopPropagation();
        // Chrome requires returnValue to be set
        e.returnValue = '';
        // delete e['returnValue'];
        return 'show message';
      }
      return null;
    };
    window.addEventListener('beforeunload', confirmExit);

    return () => {
      window.removeEventListener('beforeunload', confirmExit);
    };
  }, [isDirty]);

  useEffect(() => {
    if (gridRef?.current?.grid) gridRef.current.grid.allowSorting = !editing;
  }, [editing]);

  const dirtify = React.useCallback(() => {
    setIsDirty(true);
  }, []);

  const onCancelChanges = React.useCallback(() => {
    setIsDirty(false);
  }, []);

  const autoSizeColumnsFn = React.useCallback((flex: any) => {
    flex.autoSizeColumns();
  }, []);

  const gridInitialized = React.useCallback(
    async (flex: any) => {
      flex.allowDragging = true;
      // flex.allowPinning = "SingleColumn";
      flex.allowAddNew = true;
      flex.allowDelete = true;
      flex.keyActionTab = 'CycleEditable';
      // flex.keyActionEnter = 'CycleEditable';
      flex.refreshOnEdit = false;
      flex.validateEdits = false; // Adding so when validation occurs BringValue is not corrupted and called again and again
      flex.autoGenerateColumns = false;
      flex.topLeftCells.columns[0].cellTemplate = ($: any) =>
        $.text || ($.row.index + 1).toString();
      flex.cellEditEnding.addHandler(bringValue);

      /* Listen if grid gets dirty */
      flex.cellEditEnded.addHandler(dirtify);
      flex.deletedRow.addHandler(dirtify);
      flex.rowAdded.addHandler(dirtify);

      /* Auto size columns whenever necessary */
      if (autoSizeColumns) {
        autoSizeColumnsFn(flex);
        flex.loadedRows.addHandler(autoSizeColumnsFn);
        flex.cellEditEnded.addHandler(autoSizeColumnsFn);
      }

      if (setupLater) {
        flex.loadingRows.addHandler(() => setStatus('loading'));
        flex.loadedRows.addHandler(() => setStatus('success'));
      }

      try {
        if (!setupLater && dataKey) {
          const view = new QubCollectionView(dataKey, {
            key: 'id',
            pageSize: 20,
            hostGrid: flex,
            fetchGridParams: fetchGridParams,
            fetchGridData: fetchGridData,
            saveState: saveState,
            restoreState: restoreState,
            getError: validate,
            trackChanges: true,
            ...collectionViewProps,
          });

          await view.setup('id');

          view.loaded.addHandler(() => {
            setPagination((prev: any) => ({ ...prev, view }));
            setStatus('success');
          });
          view.loading.addHandler(() => {
            setStatus('loading');
          });
          view.error.addHandler(() => {
            setStatus('error');
          });

          flex.itemsSource = view;

          /* Get export options from params */
          setExportOptions(flex.collectionView.params.exportOptions || []);
        } else if (!setupLater && !dataKey) {
          throw new QubError(
            "If you don't set setupLater=true then provide a dataKey",
            null,
            'useDataGrid::gridInitialized',
            'FlexInitializationException'
          );
        }

        if (additionalInitialization) additionalInitialization(flex);

        new FlexGridContextMenu(flex, [
          { header: 'AutoSize All', cmd: 'ASZ_ALL' },
          { header: 'Save state', cmd: 'SAVE_STATE' },
        ]);
        gridRef.current.grid = flex;
        setInitializedGrid(true);
      } catch (error) {
        if (isQubError(error)) {
          const myError = new QubError(
            error?.message || 'Error in flexGrid initialization',
            error,
            'useDataGrid::gridInitialized',
            'FlexInitializationException'
          );
          raiseNotification({
            error: myError,
            severity: 'error',
          });
        } else {
          console.error('useDataGrid::gridInitialized', error);
        }
      }
    },
    [dataKey]
  );

  /**
   * Built-in functions to get grid changes
   * @returns changes made to grid
   */
  const getChanges = useCallback(() => {
    // const changes = gridRef.current?.grid?.collectionView?.changes; //Commenting due to error with designed function that gets changes from LS... Using built-in function
    const edited = gridRef.current?.grid?.collectionView?.itemsEdited.map(
      (item: any) => item
    );
    const created = gridRef.current?.grid?.collectionView?.itemsAdded.map(
      (item: any) => item
    );
    const deleted = gridRef.current?.grid?.collectionView?.itemsRemoved.map(
      (item: any) => item
    );

    return {
      edited,
      created,
      deleted,
    };
  }, []);

  /**
   * Function running onSubmit to reinitialize dirty state
   */
  const resetChanges = useCallback(() => {
    setIsDirty(false);
  }, []);

  const submitChanges = useCallback(() => {
    if (commitChanges instanceof Function) {
      commitChanges({
        dataKey: dataKey,
        ...getChanges(),
      })
        .then(() => {
          gridRef.current?.grid.collectionView.clearChanges(); // Clear changes after commit
          gridRef.current?.grid.collectionView.refresh();
          gridRef.current?.grid.collectionView.load();
          resetChanges();
        })
        .catch((err: AxiosError) => {
          console.error('Submit changes', err);
        });
    } else {
      console.error(
        'useDataGrid::submitChanges',
        'Please provide a commit changes function for CRUD functionality'
      );
      const error = {
        message:
          'You should provide a commit changes function to use CRUD functionality',
      };
      // throw('You should provide a commit changes function to use CRUD functionality')
      const myError = new QubError(
        'You should provide a commit changes function to use CRUD functionality',
        error,
        'useDataGrid::submitChanges',
        'CommitDataException'
      );
      raiseNotification({
        error: myError,
        severity: 'error',
      });
    }
  }, []);

  const defaultCellTemplateByColumn = useCallback(
    (column: GridColumn, header: GridColumn, cellType: 'CellEdit' | 'Cell') => {
      // console.log('Cell template by column: ', cellTemplateByColumn);
      // console.log('Cell template by column null?  ', !cellTemplateByColumn);

      if (cellTemplateByColumn && cellTemplateByColumn instanceof Function) {
        const override = cellTemplateByColumn(column, header, cellType);
        if (override) {
          return override;
        }
      }

      return null;
    },
    []
  );

  const validate = useCallback((item: any, prop: any, parsing: any) => {
    let validation: string;
    const view = gridRef.current.grid.collectionView;
    if (view.header) {
      const indexOfProp = findIndex(view.header, { binding: prop });
      if (view.header[indexOfProp]?.isRequired && !item[prop]) {
        validation = 'This field is required';
      }
    }

    if (customValidation) validation = customValidation(item, prop, parsing);
    else {
      validation = '';
    }

    return validation;
  }, []);

  return {
    dataKey,
    status,
    gridRef,
    pagination,
    setPagination,
    editing,
    setEditing,
    bringValueProps,
    gridInitialized,
    submitChanges,
    cellTemplateByColumn: defaultCellTemplateByColumn,
    flexProps,
    setupLater,
    onCancelChanges,
    isDirty,
    exportOptions,
    getGrid: () => gridRef?.current?.grid,
    initializedGrid,
  };
};

export default useDataGrid;
