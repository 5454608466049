import { RestCollectionView } from "@grapecity/wijmo.rest";
import { isFunction, findIndex, isNil } from "lodash";
import { copy, asString } from '@grapecity/wijmo';
import { Operator } from '@grapecity/wijmo.grid.filter';
import { QubError } from "./models/exceptions";
import { GridChanges, CustomOptions, GridDataResponse, GridParams, GridColumn, BringValueProps, GridParamsResponse, GlobalSearch, GridFilter, BringValueCoordinates } from './types';
import { buildHeader, isQubError, qubOperator } from "./functions/helpers";
import { raiseNotification } from "./events";
import QubEvent from "./QubEvent";

export default class QubCollectionView extends RestCollectionView {
    _key!: string;
    _tbl!: string;
    _hostGrid!: any;
    _changes?: GridChanges;
    _pagination?: boolean;
    _fetchGridData!: (params: any) => GridDataResponse;
    _fetchGridParams?: (params: any) => GridParamsResponse;
    _saveState?: (params: any) => void;
    _restoreState?: (params: any) => void;
    _params?: GridParams;
    _header?: GridColumn[];
    _data?: GridDataResponse;
    _bringValue?: BringValueProps;
    _bringValueRunning?: boolean;
    _globalSearch?: GlobalSearch;
    _customFilters?: GridFilter[];
    _bvCoordinates?: BringValueCoordinates;
    /* Custom events */
    headerLoaded?: QubEvent;
    paramsLoaded?: QubEvent;

    constructor(
        dataKey: string, 
        options: any, 
        custom: CustomOptions = { 
            fetchGridData: defaultFetchData
        }) {
        /**
         * This condition is to pass custom properties in options without super() crushing
         */
        if(options.fetchGridData){
            custom.fetchGridData = options.fetchGridData;
            delete options.fetchGridData;
        }
        if(options.fetchGridParams) {
            custom.fetchGridParams = options.fetchGridParams;
            delete options.fetchGridParams;
        }
        if(options.saveState) {
            custom.saveState = options.saveState;
            delete options.saveState;
        }
        if(options.restoreState) {
            custom.restoreState = options.restoreState;
            delete options.restoreState;
        }
        if(options.hostGrid) {
            custom.hostGrid = options.hostGrid;
            delete options.hostGrid
        }
        if(!isNil(options.pagination)) {
            custom.pagination = options.pagination;
            delete options.pagination
        }
        if(options.pageSize) {
            custom.pageSize = options.pageSize;
            delete options.pageSize;
        }

        super();
        this._tbl = asString(dataKey);
        if(custom.hostGrid) this._hostGrid = custom.hostGrid;

        /* Bind fetchGridData function in this object */
        this._fetchGridData = custom.fetchGridData;
        if(custom.fetchGridParams) this._fetchGridParams = custom.fetchGridParams;
        if(custom.saveState) this._saveState = custom.saveState;
        if(custom.restoreState) this._restoreState = custom.restoreState;
        copy(this, options);

        /**
         * This condition is to force the programmer to declare fetchGridData function
         */
        if(!isFunction(this._fetchGridData)){
            const myError = new QubError('Please specify fetchGridData function', null, 'QubCollectionView::constructor', 'FetchGridDataException');
            throw myError;
        }

        /* Default pageSize => 20 */
        if(custom.pageSize) this.pageSize = custom.pageSize;
        else this.pageSize = 20;

        /**
         * Default pagination = true. If pagination is false -> return all data once
         */
        if(!isNil(custom.pagination)) this.pagination = custom.pagination;
        else this.pagination = true;

        /**
         * Initiate custom events by passing to them the host grid
         */
        this.headerLoaded = new QubEvent(this._hostGrid);
        this.paramsLoaded = new QubEvent(this._hostGrid);
    }

    async setup(key?: string) {
        try {
            if (this._fetchGridParams) {
                const result = await this._fetchGridParams({ dataKey: this.dataKey });
                const {
                    header,
                    params
                } = result;

                // console.log('HEADER', header);
    
                this.header = header;
                this.params = params;
                this.key = params.primaryKeyName;
    
                if(this._hostGrid) {
                    buildHeader(this._hostGrid, this.header);
                    await this.restoreState();
                }
            } else {
                // console.log('Heeey i am here')
                if(key) this.key = key;
                else {
                    const myError = new QubError('Please specify key or a fetchGridParams function', null, 'QubCollectionView::setup', 'KeyMissingException');
                    throw myError;
                }

                if(this._hostGrid) {
                    this._hostGrid.autoGenerateColumns = true;
                }
            }
        } catch (error) {
            if(isQubError(error)){
                const myError = new QubError(error?.message || 'Something went wrong', error, 'QubCollectionView::setup', 'FetchParamsException');
                raiseNotification({
                    error: myError,
                    severity: 'warning'
                });
            } else {
                console.error(error);
            }
        }
    }

    async saveState() {
        try {
            if (this._saveState && isFunction(this._saveState)) {
                await this._saveState({
                    dataKey: this.dataKey,
                    gridState: {
                        columnLayout: this._hostGrid.columnLayout,
                        pageSize: this.pageSize
                    }
                });
            }
        } catch (error) {
            console.error(error);
        };
    }

    async restoreState() {
        try {
            if ( this._restoreState && isFunction(this._restoreState)) {
                const result: any = await this._restoreState({
                    dataKey: this.dataKey
                });
                console.log(result);
                this._hostGrid.columnLayout = result?.columnLayout;

                if (result?.pageSize) 
                    this.pageSize = result?.pageSize;
            }
        } catch (error) {
            console.error(error);
        };
    }
    
    async getItems() {
        try{
            const filters = this._query();
            if (this.globalSearch && this.globalSearch.value) {
                filters.push({
                    filter: this.globalSearch.value,
                    operation: 'custom',
                    searchField: this.globalSearch.field
                });
            }
            if (this.customFilters && Array.isArray(this.customFilters)) {
                this.customFilters.forEach((filter) => {
                    filters.push(filter);
                });
            }

            const sortField = this.sortDescriptions[0];
            let sortOrder = 'asc';

            if(this.sortOnServer) sortOrder = (sortField?.ascending || isNil(sortField?.ascending)) ? 'asc' : 'desc';
            

            const result = await this._fetchGridData({
                dataKey: this._tbl,
                currentPage: this.pageIndex,
                filters,
                pageSize: (this.pageOnServer && this.pagination) ? this.pageSize : 100, // If pagination is disabled explicitly then return 100 rows
                orderBy: this.sortOnServer ? sortField?.property : null,
                sortOrder: sortOrder
            });

            console.log(`QubCollectionView::getItems::${this.dataKey}`, filters, result);
            
            this.data = result;
            this._totalItemCount = result.itemsCount;
            
            // console.log('this is data', result)
            return result?.items || [];
        }
        catch(error) {
            if(isQubError(error)){
                const myError = new QubError(error?.message || 'Something went wrong', error, 'QubCollectionView::getItems', 'FetchDataException');
                raiseNotification({
                    error: myError,
                    severity: 'warning'
                });
            } else {
                console.error(error);
            }
        }
        return [];
    }
    async addItem(item: any) {
        const created = this._getControl('created');
        // console.log(created);
        created?.push(item);
        // console.log(created);
        this._updateControl('created', created);
    }
    async patchItem(item: any) {
        const edited = this._getControl('edited');
        const created = this._getControl('created');
        const index = this._findIndexIn(item, created);
        if(index >= 0 && Array.isArray(created)){
            // console.log('hehrerhreh')
            created[index] = item;
            this._updateControl('created', created);
        } else {
            const editedIndex = this._findIndexIn(item, edited);
            if(editedIndex >= 0 && Array.isArray(edited)) edited[editedIndex] = item;   // Already edited
            else edited?.push(item);                                // To be inserted now in edited
            // console.log(edited);
            this._updateControl('edited', edited);
        }
    }
    async deleteItem(item: any) {
        const deleted = this._getControl('deleted');
        const created = this._getControl('created');
        const index = this._findIndexIn(item, created);
        if(index >= 0 && Array.isArray(created)){
            this._updateControl('created', created.splice(index, index));
        } else {
            // console.log(deleted);
            deleted?.push(item);
            // console.log(deleted);
            this._updateControl('deleted', deleted);
        }
    }

    executeGlobalSearch(globalSearch: GlobalSearch) {
        if(!globalSearch.field){
            globalSearch.field = this.params?.searchField
        }
        this.globalSearch = globalSearch;
        this.load();
    }

    _findIndexIn(item: any, array?: Object[]){
        if(this.key && item[this.key] && array && Array.isArray(array)) {
            const itemIndex = findIndex(
                array,
                item // Not working due to not creating an ID => { [this.params?.primaryKeyName || this.key]: item[this.params?.primaryKeyName || this.key] }
            );

            return itemIndex;
        }

        return -1;
    }

    _getControl(type: 'created' | 'edited' | 'deleted'){
        let changes = this._changes;
        if(!changes) 
            changes = {};

        if(!(changes.hasOwnProperty(type) && changes[type] && Array.isArray(changes[type]))){
            changes[type] = [];
        }
        return changes[type];
    }
    _updateControl(type: 'created' | 'edited' | 'deleted', updated: any){
        this._changes = { 
            ...this._changes, 
            [type]: updated
        }
        // console.log('GRID CHANGES', this._changes);
    }
    
    _fetchFromLS(name: string) {
        try {
            const value = localStorage.getItem(name);
            if (!value) {
                const myError = new QubError('Error fetching from LS', null, 'QubCollectionView::_fetchFromLS', 'FetchFromLSException');
                throw myError;
            }
            return JSON.parse(value);
        }
        catch(error){
            console.error(`Cannot get ${name} from local storage (QubCollectionView)`);
            return null;
        }
    }

    fetchChangesFromLS() {
        const changes = this._fetchFromLS(this._tbl) || {};
        // console.log('CHSDSDLKFJ', changes);
        
        return changes;
    }

    stashChangesInLS() {
        try {
            if(this._changes && Object.keys(this._changes)?.length > 0)
                localStorage.setItem(this._tbl, JSON.stringify(this._changes));
            return 1;
        }
        catch(error){
            console.error(`Cannot stash changes in local storage (QubCollectionView)`);
            return null;
        }
    }

    resetChanges() {
        try {
            localStorage.removeItem(this._tbl);
        } catch (error) {
            console.error(`Cannot restore changes in local storage (QubCollectionView)`);
        }
    }

    _restoreStashedChanges() {
        if(Array.isArray(this._changes?.edited)) {
            this._changes?.edited.map(item => {
                // console.log('Restoring', item)
                this.editItem(item);
                this.commitEdit();
                return item; // To resolve warning
            })
        }
    }

    getColumnByBinding(binding: string) {
        if(this._header){
            const itemIndex = findIndex(this._header, { binding });

            return this._header[itemIndex];
        } else {
            const myError = new QubError('No params given', null, 'QubCollectionView::getColumnByBinding', 'NoParamsProvidedException');
            throw myError;
        }
    }

    _query() {
        const filter = this._getQueryFilters()
        // console.log('Array of filterObjects', filter)
        return filter;
    }
    
    _getQueryFilters() {
        let filters: any = [];
        let filter = this._filterProvider;
        if (filter) {
            for (let c = 0; c < filter.grid.columns.length; c++) {
                let col = filter.grid.columns[c], cf = filter.getColumnFilter(col, false);
                if (cf && cf.isActive) {
                    if (cf.conditionFilter && cf.conditionFilter.isActive) {
                        this._getQueryConditionFilter(filters, cf.conditionFilter);
                    }
                    // FILTER BY VALUE NOT SUPPORTED YET.
                    // else if (cf.valueFilter && cf.valueFilter.isActive) {
                    //     this._getQueryValueFilter(filters, cf.valueFilter);
                    // }
                }
            }
        }
        return filters;
    }
    _getQueryConditionFilter(filters: any, cf: any) {
        let path = cf.column.binding, sf1 = this._getQuerySimpleFilter(cf.condition1, path), sf2 = this._getQuerySimpleFilter(cf.condition2, path);
        if (sf1 && sf2 && cf.and) {
            filters.push({
                compositeFilter: {
                    op: cf.and ? 'AND' : 'OR',
                    filters: [sf1, sf2]
                }
            });
        }
        else if (sf1) {
            filters.push(sf1);
        }
        else if (sf2) {
            filters.push(sf2);
        }
    }
    _getQuerySimpleFilter(fc: {isActive: boolean, operator: Operator, value: any}, path: any) {
        if (fc.isActive) {
            // console.log(fc.operator);
            return {
                    filter: fc.value,
                    operation: qubOperator(fc.operator),
                    searchField: path
                }
            
        }
        return null;
    }
    getBvDependents(props: {bringValueTable: string, binding?: string}) {
        let calledFrom: GridColumn | undefined;
        if(!isNil(props.binding)){
            calledFrom = this._header?.find((h: GridColumn) => h.binding === props.binding);
        }
        if(!isNil(calledFrom?.dependents)){
            const dependents = calledFrom!.dependents.split(',');
            dependents.push(props.binding!);
            return this._header?.filter((col) => {
                return dependents!.findIndex((c: string) => c === col.binding) > -1;
            });
        }
        return this._header?.filter((col) => {
            return col.bringValueTable === props.bringValueTable;
        })
    }
    clearBvDependents(bringValueTable: string, rowIndex: Number) {
        const dependents = this.getBvDependents({ bringValueTable });
        if (dependents && Array.isArray(dependents)) {
            dependents.forEach((dependent) => {
                const gridColumn = this._hostGrid.getColumn(dependent.binding);
                this._hostGrid.setCellData(rowIndex, gridColumn.index, null);
            })
        }
    }
    /** getters & setters */
    get key() {
        return this._key;
    }
    
    set key(value) {
        this._key = asString(value);
    }
    
    get header() {
        return this._header;
    }
    
    set header(value) {
        this.headerLoaded?.emit({ header: value });
        this._header = value;
    }
    
    get params() {
        return this._params;
    }
    
    set params(value) {
        this.paramsLoaded?.emit({ params: value });
        this._params = value;
    }

    get pagination() {
        return this._pagination;
    }

    set pagination(value) {
        this._pagination = value;
    }
    
    get data() {
        return this._data;
    }
    
    set data(value) {
        this._data = value;
    }
    get bringValueProps() {
        return this._bringValue;
    }
    set bringValueProps(value) {
        this._bringValue = value;
    }
    get dataKey() {
        return this._tbl;
    }
    get changes() {
        return this._changes;
    }
    get bringValueRunning(){
        return this._bringValueRunning
    }
    set bringValueRunning(value){
        this._bringValueRunning = value;
    }
    toggleBringValueRunning() {
        this._bringValueRunning = !this._bringValueRunning
    }
    get filterProvider() {
        return this._filterProvider;
    }
    get globalSearch() {
        return this._globalSearch
    }
    set globalSearch(value) {
        this._globalSearch = value;
    }
    get customFilters() {
        return this._customFilters;
    }
    set customFilters(value) {
        this._customFilters = value;
    }
    get bvCoordinates() {
        return this._bvCoordinates;
    }
    set bvCoordinates(value) {
        this._bvCoordinates = value;
    }
}

export const defaultFetchData = () => { 
    alert('Please give datagrid a fetchGridData function'); 
    return {
        itemsCount: 1,
        items: [
            {
                please: 'Please',
                give: 'Give',
                me: 'Me',
                a: 'A',
                fetchGridData: 'fetchGridData',
                function: 'Function'
            }
        ]
    }
}