import React from 'react';

/* third-party imports */
import { DataMap } from '@grapecity/wijmo.grid';
import {
  FlexGrid,
  FlexGridColumn,
  FlexGridCellTemplate,
} from '@grapecity/wijmo.react.grid';
import '@grapecity/wijmo.styles/wijmo.css';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';

/* project imports */
import useQGridLogic from './useQGridLogic';
import { QGridProps, GridColumn } from './types';
import { TablePagination, Button, DialogTitle, Dialog, DialogContent, DialogActions, DialogContentText } from '@mui/material';

/**
 * 
 * @param {QGridProps} props GridProps configuration
 * @param ref Ref that provides the wijmo FlexGrid class object
 * @returns JSX component that renders a wijmo FlexGrid with specified columns & rows
 * 
 * @example 
 *  const App = () => {
    const ref = React.useRef(null);
    const [columns, setColumns] = React.useState([]);
    const [rows, setRows] = React.useState([]);

    return (
      <QGrid
        ref={ref}
        columns={columns}
        rows={rows}
        bringValue={{
          getData: handleBringValue,
          getParams: getParams,
        }}
        onRowsChanged={(rows) => setRows(rows)}
      />
    );
  };
 */
  
  
const QGrid = React.forwardRef(
  (props: QGridProps, ref: React.ForwardedRef<any>) => {
    const {
      columns,
      gridInitialized,
      rows,
      flexGridProps,
      paginationOptions: { onPaginationChanged, ...paginationOptions },
      paginationState,
      columnPickerOptions,
    } = useQGridLogic(props, ref);

    const { t, i18n } = useTranslation(props.translationNs ?? 'grids');

    return (
      <div className="Qub-DatagridContainer">
        <div className="Qub-DatagridFlexContainer">
          <FlexGrid
            autoSizeMode={3}
            initialized={gridInitialized}
            itemsSource={rows}
            autoGenerateColumns={isNil(columns)}
            {...flexGridProps}
          >
            {props.children}
            {columns?.length > 0 &&
              columns.map((c: GridColumn, index: number) => {
            
                if (
                  isNil(c.header) &&
                  c.visible !== false &&
                  !isNil(i18n.language)
                )
                  c.header = t(c.binding);

                let dataMap = undefined;
                if (!isNil(c.options) && Array.isArray(c.options))
                  dataMap = new DataMap(c.options, 'id', 'value');

                return (
                  <FlexGridColumn
                    key={index}
                    {...c}
                    header={c.isBringValue ? `${c.header}  🔍︎` : c.header}
                    dataMap={dataMap}
                  >
                    {c.cellTemplates?.map((ct, i) => (
                      <FlexGridCellTemplate
                        key={i}
                        cellType={ct.cellType}
                        template={(ctx: any) => ct.template(ctx, c)}
                      />
                    ))}
                  </FlexGridColumn>
                );
              })}
          </FlexGrid>
          <Dialog
            open={columnPickerOptions.showColumnPicker}
            onClose={columnPickerOptions.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            
          >
            <DialogTitle id="alert-dialog-title">{t('ColumnPicker')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{t('ChooseColumn')}</DialogContentText>
                {columnPickerOptions.contentModal}
                {props.children}
            </DialogContent>
            <DialogActions>
                <Button onClick={columnPickerOptions.onAccept} color="primary">
                    {t('Submit')}
                </Button>
                <Button onClick={columnPickerOptions.onClose} color="primary" >
                    {t('Cancel')}
                </Button>
            </DialogActions>
          </Dialog>
        </div>
        {!isNil(props.paginationOptions) && (
          <div className="Qub-DatagridPaginationContainer">
            {rows.length > 0 && (
              <TablePagination
                component="div"
                {...paginationOptions}
                {...paginationState}
              />
            )}
          </div>
        )}
      </div>
    );
  }
);
export default QGrid;
