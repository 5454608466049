import React, { useRef, useEffect, useState } from 'react';
import { Popup } from '@grapecity/wijmo.react.input';
import { GridFilter, GridRef } from '../types';
// import { KeyAction, SelectionMode } from '@grapecity/wijmo.grid';
import { useDataGrid } from '../hooks';
import DataGrid from '../DataGrid';
import QubCollectionView from '../QubCollectionView';
import { raiseBringValueClose } from '../events';
import '../styles/utilities.css';
import { isFunction } from 'lodash';
import { Operator } from '@grapecity/wijmo.grid.filter/index';
import { useGridProps } from '../contexts';

const BringValueView = (props: any) => {
  const { id, handleOnEnter } = props;
  const hostGridProps = useGridProps();
  const popupRef = useRef<any>();
  const gridRef = useRef<GridRef>({ grid: null });
  const filterRef = useRef<any>();
  const [filterData, setFilterData] = useState<any>();
  const [dataKey, setDataKey] = useState<string>();
  const [itemCount, setItemCount] = useState<number>();

  if (!isFunction(handleOnEnter)) {
    throw 'Specify handleOnEnter function or set bringValue false';
  }

  const additionalInitialization = (flex: any) => {
    flex.stickyHeaders = true;
    flex.selectionMode = 'Row';
    flex.keyActionEnter = 'None';
    flex.isReadOnly = true;
    const grid = hostGridProps.getGrid();

    flex.hostElement.addEventListener('dblclick', (e: any) => {
      let ht = flex.hitTest(e.pageX, e.pageY);
      const view = ht?.grid?.collectionView;
      const currentItem = view.currentItem;
      if (currentItem && ht.cellType === 1) {
        handleOnEnter({ grid, item: currentItem, dataKey: view?.dataKey });
        popupRef.current.hide();
      }
    });

    flex.hostElement.addEventListener('keydown', (e: any) => {
      const view = flex.collectionView;
      if (e.code === 'Enter' && view.currentItem) {
        e.preventDefault();
        e.stopPropagation();

        /* Runs handle select */
        handleOnEnter({ grid, item: view.currentItem, dataKey: view?.dataKey });
        popupRef.current.hide();
      }
    });

    flex.handleOnEnter = handleOnEnter;

    gridRef.current.grid = flex;
  };

  const gridProps = useDataGrid({
    setupLater: true,
    additionalInitialization: additionalInitialization,
    collectionViewProps: {
      pagination: false,
    },
  });

  useEffect(() => {
    /** BringValue event handler */
    const bringValueCalled = async (e: any) => {
      const { detail } = e;

      const { fetchGridParams, fetchGridData, bringValueTable } = detail;

      /** Trigger DataGrid component remount */
      setDataKey(detail.filterData.bringValueTable);
      setFilterData(detail.filterData);

      /** This is overloaded to fix bug caused in first getItems API call containing no filters */
      const getData = (params: any) => {
        let filters = params.filters;
        if (Array.isArray(filters) && filters.length === 0) {
          filters = detail.filterData.filter;
        }
        const result = fetchGridData({
          ...params,
          filters,
        }).then((res: any) => {
          setItemCount(res?.itemsCount);
          return res;
        }); // to get how many records the db returns

        return result;
      };

      /** useDataGrid implementation of later setup */
      const view = new QubCollectionView(bringValueTable, {
        key: 'id',
        pageSize: 10,
        hostGrid: gridRef.current.grid,
        fetchGridParams: fetchGridParams,
        fetchGridData: getData,
        pagination: false,
      });

      // Setup header
      await view.setup('id');

      view.loaded.addHandler(() => {
        gridProps.setPagination((prev: any) => ({ ...prev, view }));
      });

      /* Auto resize columns */
      view.loaded.addHandler(() => {
        gridProps.getGrid().autoSizeColumns();
      });

      gridRef.current.grid.itemsSource = view;

      /**
       * v2.0.0-beta.10 commenting due to bug when calling bringValue for 2 different columns in one table
       */
      detail.filterData.filter.forEach((bvFilter: GridFilter) => {
        const filter = filterRef.current.getColumnFilter(
          bvFilter.searchField,
          true
        ).conditionFilter;

        filter.condition1.value = bvFilter.filter;
        filter.condition1.operator = Operator.BW;
      });

      filterRef.current.apply();

      popupRef.current.show(true);
      // popupRef.current.focus();/
      gridRef.current.grid.focus();
    };

    document.addEventListener(`bringValue-${id}`, bringValueCalled);

    return () => {
      document.removeEventListener(`bringValue-${id}`, bringValueCalled);
    };
  }, []);

  /**
   * Popup initialized
   * @param popup Wijmo popup object
   */
  const popupInitialized = (popup: any) => {
    popup.isDraggable = true;
    popup.isResizable = true;
    popup.fadeOut = false;
    popup.fadeIn = false;
    popup.onHidden = () => {
      raiseBringValueClose();
    };
    /* (Hot-)Fixing bug that hides the popup when pagination options are clicked */
    popup.hideTrigger = 'None';

    popupRef.current = popup;
  };

  return (
    <Popup
      key={dataKey}
      initialized={popupInitialized}
      style={{ width: '75vw', height: '90vh' }}
    >
      <div
        className="wj-dialog-header Qub-DatagridBringValueView"
        style={{ backgroundColor: 'lightgray' }}
      >
        {`Searching '${filterData?.filter[0].filter || ''}' on ${
          filterData?.bringValueTable
        }`}
        <button type="button" tabIndex={-1} className="close wj-hide">
          &times;
        </button>
      </div>
      <div className="modal-body" hidden={gridProps.status !== 'loading'}>
        Loading...
      </div>
      <div className="modal-body" hidden={gridProps.status !== 'success'}>
        <DataGrid
          key={dataKey}
          gridProps={gridProps}
          options={{
            filtering: true,
          }}
          additional={{
            filterInitialized: (filter: any) => {
              filter.defaultFilterType = 1; // Condition
              filterRef.current = filter;
            },
          }}
          style={{
            height: 'calc(90vh - 115px)',
          }}
        />
      </div>
      <div
        className="modal-footer"
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginRight: 24,
          height: 35,
        }}
      >
        {gridProps.getGrid()?.collectionView?.itemCount ?? 0} of{' '}
        {itemCount ?? 0} records
      </div>
    </Popup>
  );
};

export default BringValueView;
