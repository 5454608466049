import { GridColumn } from '../types';

export type AutoSizeArgs = {
  flex: any;
  columns: GridColumn[] | undefined;
};

/**
 * Custom wijmo flexgrid autoSize each row to  override default autoSize
 *
 * @param {AutoSizeArgs} args
 */
export const autoSize = (args: AutoSizeArgs) => {
  const { flex, columns } = args;
  const visibleCols = flex.columns.filter((col: GridColumn) => col.visible !== false)
  columns
    ?.forEach((c: GridColumn, index: Number) => {
      if ( c.visible !== false){
        visibleCols.pop();
        const col = flex?.getColumn(c.binding);
        if (visibleCols.length === 0 ) {
          if(!col.width){
            col.width = '*';
          }
        }else if (!c.disableAutoSize) {
          flex.autoSizeColumn(index);
        }
      }
    }
  );
};
