import { BringValueHandlerProps } from "../../types"

/**
 * This function is called after an item has been selected in a bring value transaction
 * @param param object containing grid and incoming item
 */
export const handleSelectBringValueDefault = ({ grid, item, dataKey: key, resultCount }: BringValueHandlerProps) => {
    console.log('DATA KEY', key);
    console.log('RESULT COUNT', resultCount);
    const { collectionView } = grid;
    /* The dataKey of requested table */
    const dataKey = collectionView.bringValueProps.bringValueRef.current.dataKey;

    /* Get coordinates */
    const { row, col } = collectionView.bvCoordinates;
    /* Find item */
    const currentItem = grid.rows[row].dataItem;

    const currentCol = grid.columns[col];

    /* Start edit transaction */
    collectionView.editItem(currentItem);

    /* Update item according to selected row */
    const dependents = collectionView.getBvDependents({ bringValueTable: dataKey, binding: currentCol.binding });

    // console.log("DEPENDENTS", dependents)

    dependents.forEach((dependent: any) => {
        const binding = dependent.binding;
        const bringValueField = dependent.dependsOn; // Get field mapping for bring value
        grid.setCellData(row, binding, item[bringValueField]);
    });

    collectionView.commitEdit();

    grid.focus();
}