import React, { useEffect, useRef } from 'react';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { ListBox } from '@grapecity/wijmo.react.input';
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";
import { TablePagination } from '@mui/material'
import { isFunction, isNil } from 'lodash';
import { CRUDPanel, BringValueView } from './components';
import { useColumnPicker, useKeyboardActions } from './hooks';
import { GridPropsProvider } from './contexts/GridPropsContext';

export type DataGridProps = {
    children?: any,
    gridProps: any,
    options?: {
        pagination?: boolean,
        filtering?: boolean,
        crud?: boolean,
        bringValue?: boolean,
        columnPicker?: boolean
    },
    additional?: {
        filterInitialized?: Function,
        showFirst?: boolean,
        showLast?: boolean
    },
    style?: any
};

const DataGrid = (props: DataGridProps) => {
    const {
        children,
        gridProps,
        options,
        additional,
        ...rest
    } = props;

    const columnPickerRef = useRef<any>(null);
    
    useColumnPicker(gridProps, columnPickerRef);
    useKeyboardActions(gridProps, options);

    useEffect(() => {
        const view = gridProps?.gridRef?.current?.grid?.collectionView
        if(view){
            view.bringValueProps = { ...view.bringValueProps, isBringValue: options?.bringValue}
        }

        const bringValueClose = (e: any) => {
            const {
                detail
            } = e;

            if(!detail.grid){
                detail.grid = gridProps.gridRef.current.grid;
            }
        };

        document.addEventListener('bringValueClose', bringValueClose);

        return () => {
            document.removeEventListener('bringValueClose', bringValueClose);
        }
    }, [gridProps.status])

    const handleChangePage = (_: any, newPage: number) => {
        if(gridProps.gridRef.current?.grid){
            const view = gridProps.gridRef.current.grid.collectionView;
            if(newPage > view.pageIndex){
                view.moveToPage(newPage);
            } else if (newPage === 0) {
                view.moveToFirstPage();
            } else {
                view.moveToPreviousPage();
            }
        }
    }

    const handleChangeRowsPerPage = (e: any) => {
        if(gridProps.gridRef.current?.grid){
            const view = gridProps.gridRef.current.grid.collectionView;
            view.pageSize = e.target.value;
            gridProps.setPagination((prev: any) => ({...prev, view }))
        }
    }

    const initializePicker = (picker: any) => {
        columnPickerRef.current = picker;
    }
    
    return (
        <GridPropsProvider gridProps={gridProps}>
            <div className='Qub-DatagridContainer'>
                {options?.columnPicker && 
                    <div className="column-picker-div">
                        <ListBox className="column-picker" initialized={initializePicker}/>
                    </div>}
                {options?.bringValue && 
                    <BringValueView 
                        id={gridProps?.bringValueProps?.id}
                        key={gridProps.bringValueProps}
                        handleOnEnter={gridProps?.bringValueProps?.handleSelectBringValue}
                    />
                }
                {options?.crud && 
                    <CRUDPanel 
                        editing={gridProps.editing} 
                        toggleEditing={gridProps.setEditing}
                        commitChanges={gridProps.submitChanges}
                        revertChanges={() => { 
                            gridProps.onCancelChanges();
                            gridProps.gridRef?.current?.grid?.collectionView?.refresh(); 
                            gridProps.gridRef?.current?.grid?.collectionView.load(); 
                        }}
                    />
                }
                <div className='Qub-DatagridFlexContainer'>
                    <FlexGrid
                        initialized={gridProps.gridInitialized}
                        isReadOnly={!gridProps.editing}
                        {...gridProps.flexProps}
                        {...rest}
                    >
                        {options?.filtering && 
                            <FlexGridFilter 
                                initialized={isFunction(additional?.filterInitialized) ? additional?.filterInitialized : null}
                                showSortButtons={false}
                            />
                        }
                        {children}
                    </FlexGrid>
                </div>
                {options?.pagination && 
                    <div className='Qub-DatagridPaginationContainer'>
                        <TablePagination
                            component="div"
                            showFirstButton={isNil(additional?.showFirst) ? true : additional?.showFirst}
                            showLastButton={isNil(additional?.showLast) ? true : additional?.showLast}
                            count={gridProps.pagination.view?.data?.itemsCount || 0}
                            page={gridProps.pagination.view?.pageIndex || 0}
                            onPageChange={handleChangePage}
                            rowsPerPageOptions={gridProps.pagination.view?.params?.pageSizes?.split(',')?.map((size: any) => parseInt(size)) || [10, 20]}
                            rowsPerPage={gridProps.pagination.view?.pageSize || 10}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                }
            </div>
        </GridPropsProvider>
    )
}

export default DataGrid;