import { createCustomEvent } from '../functions/core';

/**
 * Function that throws BringValue event and passes the filter and rest params in details
 * @param params Parameters to pass in details of thrown event
 */
export const raiseBringValue = async (params: any) => {
    try{
        const {
            id,
            filter,
            fetchGridParams,
            fetchGridData,
            bringValueTable,
            ...rest
        } = params;

        const typeOfEvent = createCustomEvent(`bringValue-${id}`, {
            fetchGridParams,
            fetchGridData,
            bringValueTable,
            filterData: {
                filter,
                bringValueTable
            },
            ...rest
        });

        if(typeOfEvent && filter)
            document.dispatchEvent(typeOfEvent);
    }
    catch(error) {
        console.error('Raise bring value error', error)
        throw(error);
    }
}

/**
 * Function that throws BringValueCloseEvent
 * @param params Parameters to pass in details of thrown event
 */
export const raiseBringValueClose = (params?: any) => {
    try{
        const typeOfEvent = createCustomEvent(`bringValueClose`, {
            ...params
        });

        if(typeOfEvent)
            document.dispatchEvent(typeOfEvent);
    }
    catch(error) {
        console.error('Dispatch bring value error', error)
    }
}