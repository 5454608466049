import { Column } from "@grapecity/wijmo.grid";
import { GridColumn } from "../../types";

/**
 * 
 * @param obj A wijmo GridColumn object
 * @returns An object without null properties
 */
export function removeEmpty(obj: GridColumn) {
    removeCustom(obj);
    return Object.entries(obj)
      .filter(([_, v]) => v != null)
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
}

function removeCustom(obj: GridColumn) {
    delete obj.isBringValue;
    delete obj.bringValueTable;
    delete obj.bringValueField;
    delete obj.dependsOn;
    delete obj.position;
    delete obj.options;
    delete obj.isPickable;
    delete obj.dependents;

    return obj;
}

/**
 * Function that builds the header of FlexGrid using unbound method 
 * @param flex FlexGrid class instance
 * @param header QubDataGrid header returned by DB
 * @param cellTemplate Function that changes the cellTemplate of a column
 */
export const buildHeader = (flex: any, header: GridColumn[], cellTemplate?: Function) => {
    if (flex.columns.length === 0) {
        header.forEach((column: GridColumn) => {
            const cleanCol = removeEmpty({ 
                ...column, 
                cellTemplate: cellTemplate ? cellTemplate(column, header, 'Cell') : column.cellTemplate,
                // editCellTemplate: cellTemplate(column, header, 'CellEdit')
            });
            flex.columns.push(new Column(cleanCol));
        });

        // header.push({
        //     binding: 'filler',
        //     visible: true,
        //     header: ' ',
        //     width: '*',
        //     isReadOnly: true,
        //     allowSorting: false
        // });
    }
}