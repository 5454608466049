import React, { useRef, useState } from 'react';
import { AxiosError } from 'axios';
import {
  BringValueCoordinates,
  BringValueHandlerProps,
  BringValueProps,
  BringValueRef,
  GridFilter,
  GridHandlerProps,
} from '../../types';
import {
  raiseBringValue,
  raiseBringValueClose,
  raiseNotification,
} from '../../events';
import { QubError } from '../../models/exceptions';
import { isQubError } from '../../functions/helpers';
import { handleSelectBringValueDefault } from '../../functions';
import { v4 as uuidv4 } from 'uuid';

export interface BringValueParams {
  dataKey: string;
  filters: GridFilter[];
}
/**
 * Custom hook to add bring value functionality to wijmo data grid
 * @param handleBringValue Function to provide data with additionalFiltering functionality etc.
 * @param handleSelectBringValue Function to select a row and match it with current row
 * @param handleClose Function to run on close modal
 * @returns
 */
const useBringValue = (
  handleBringValue: (args: BringValueParams, options?: any) => any,
  fetchGridParams: Function,
  handleSelectBringValue: (
    params: BringValueHandlerProps
  ) => void = handleSelectBringValueDefault,
  handleClose?: Function
): BringValueProps => {
  const [id] = useState(uuidv4()); // This is for listening only to my bringValue events hehe

  const bringValueRef = useRef<BringValueRef>({
    dataKey: undefined,
    filter: undefined,
    data: undefined,
  });

  const bringValueCoordinates: React.MutableRefObject<BringValueCoordinates> =
    React.useRef({ row: undefined, col: undefined });

  /**
   * Extend given function to chose items automatically if count = 1
   * @param gridProps Provide wijmo flex grid object ( to avoid unpredictable state logic ) and custom header
   * @param bringValueTable Provide data key to execute bring value
   * @param filter Provide filter object
   */
  const bringValue = async (
    handlerProps: GridHandlerProps,
    bringValueTable: string,
    filter: GridFilter[]
  ) => {
    try {
      bringValueRef.current.dataKey = bringValueTable;

      const data = await handleBringValue(
        {
          dataKey: bringValueTable,
          filters: filter,
        },
        {
          handlerProps,
        }
      ).catch((err: AxiosError) => {
        throw err;
      });

      const { itemsCount, items } = data;

      bringValueRef.current.data = { items, itemsCount };


      switch (itemsCount) {
        case 0:
          console.error('No such data');
          raiseBringValueClose({ ...handlerProps });
          return false;
        case 1:
          handleSelectBringValue({
            grid: handlerProps.grid,
            item: items[0],
            dataKey: bringValueTable,
            resultCount: 1,
          });
          raiseBringValueClose({ ...handlerProps });
          return true;
        default:
          bringValueRef.current.filter = filter;
          // const result = await fetchGridParams({ dataKey: bringValueTable });
          raiseBringValue({
            id,
            fetchGridParams,
            fetchGridData: handleBringValue,
            filter,
            data,
            bringValueTable,
          });
          return true;
      }
    } catch (error) {
      if (isQubError(error)) {
        const myError = new QubError(
          error?.message || 'Something went wrong',
          error,
          'useBringValue::bringValue',
          'BringValueException'
        );
        console.error(myError);
        raiseNotification({
          error: myError,
          severity: 'warning',
        });
      } else {
        console.error('useBringValue::bringValue', error);
      }
      return false;
    }
  };

  /**
   * Custom onClose method, to be extended on custom handleClose
   */
  const close = () => {
    if (handleClose instanceof Function) handleClose();
  };

  return {
    id,
    bringValue,
    handleSelectBringValue,
    close,
    bringValueRef,
    bringValueCoordinates,
  };
};

export default useBringValue;
