import React, { useContext } from 'react';
import { Popup } from '@grapecity/wijmo.react.input';

import { UseQBringValueProps } from './types';
import { notImplemented } from '../QGrid/utils';
import QGrid from '../QGrid';
import useQBringValueLogic from './useQBringValueLogic';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash';
import { FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';

const Context = React.createContext<UseQBringValueProps>({
  bringValue: notImplemented(
    "DatagridError::Too soon to use bringValue (shouldn't be thrown ever)"
  ),
});

function useQBringValue(): UseQBringValueProps {
  const qBringValueContext = useContext(Context);
  if (qBringValueContext === undefined) {
    throw new Error(
      'useQBringValue should be used within a QBringValueProvider'
    );
  }
  return qBringValueContext;
}

const QBringValueProvider = (props: { children: React.ReactNode, translationNs?: string }) => {
  const {
    bringValueState,
    bringValue,
    popupInitialized,
    bringValueRef,
    bvGridRef,
    additionalInitialization,
  } = useQBringValueLogic();

  const {translationNs } = props;
  const { t, i18n } = useTranslation(translationNs);

  return (
    <Context.Provider value={{ bringValue, ref: bringValueRef }}>
      <Popup
        initialized={popupInitialized}
        style={{ width: '75vw', height: '90vh' }}
      >
        <div
          className="wj-dialog-header Qub-DatagridBringValueView"
          style={{ backgroundColor: 'lightgray' }}
        >
          <div>
            <h4 style={{margin: 0}}>{`${isNil(i18n.language) ? 'Searching' : t('Searching')} '${
                bringValueState?.filter ||
              ''
            }'`}</h4>
            <h6 style={{marginBottom: 0, marginTop: 5}}>{bringValueState?.description}</h6>
          </div>
          <button type="button" style={{height: '4vh', width: '2vw'}} tabIndex={-1} className="close wj-hide">
            &times;
          </button>
        </div>
        <QGrid
          ref={bvGridRef}
          rows={bringValueState?.rows ?? []}
          columns={bringValueState?.columns}
          initializeGrid={additionalInitialization}
          disableColumnPicker
          style={{
            height: 'calc(90vh - 115px)',
          }}>
            <FlexGridCellTemplate cellType="RowHeader" template={(context: any) => context.row.index + 1}/>
        </QGrid>
        <div
          className="modal-footer"
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginRight: 24,
            height: 35,
          }}
        >
          {bringValueState?.rows?.length ?? 0} of{' '}
          {bringValueState?.rowCount ?? 0} {isNil(i18n.language) ? 'records' :t('records')}
        </div>
      </Popup>
      {props.children}
    </Context.Provider>
  );
};

const QBringValueConsumer = Context.Consumer;

export { useQBringValue, QBringValueProvider, QBringValueConsumer };
